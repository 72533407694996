// @ts-strict-ignore
import React from 'react'

/**
 *
 * 어드민으로 로그인한 경우 링글멤버에게 보여지는 고정 라벨
 */

const AdminUserBadge: React.FC<any> = () => {
  return (
    <div
      style={{ zIndex: 9999 }}
      className="fixed top-[10px] left-[10px] inline flex items-center justify-center rounded bg-yellow-500 bg-opacity-90 px-[12px] py-[4px] text-center text-[10px] text-white shadow"
    >
      (어드민으로 로그인됨- GTM,GA 트레킹 되지 않음)
    </div>
  )
}

export default AdminUserBadge
