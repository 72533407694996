// @ts-strict-ignore
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { sendGTMBasicClickEvent } from '../../helpers/gtmHelper'
import { useLogoutTutor } from '../../modules/auth'
import LinkLocale from '../../modules/i18n/LinkLocale'
import { s_menu } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { DATA_REF_KEY } from '../../modules/ids/e2e/student'
import { TUTOR_LANDING_BTN_ID } from '../../modules/ids/gtm/tutor'
import { RINGLE_LOGO_ROUND } from '../../modules/images'
import urls from '../../modules/urls'
import { SIGN_UP_MODE } from '../../modules/vars'
import Button from '../_legacy/Button'
import TutorPortalEnterButton from '../common/buttons/TutorPortalEnterButton'
// {/* 모바일 메뉴 리스트 */ }
const MobileMenu = ({ currentUser, open }: { currentUser: any; open: boolean }) => {
  const { t } = useTranslation()
  const handleLogout = useLogoutTutor()

  return (
    <>
      {/* 모바일 상단 헤더 */}
      <div className="flex flex-1 items-center border py-[13px] px-[20px]">
        <div className="flex flex-1 justify-between">
          <LinkLocale href={urls.tutor.landing.home}>
            <img src={RINGLE_LOGO_ROUND} className="block h-[31px] w-[31px] w-auto lg:hidden" />
          </LinkLocale>
          {/* 모바일 닫기 버튼 */}
          <Disclosure.Button className="inline-flex items-center justify-self-end rounded-md text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span className="sr-only">Open main menu</span>
            {open ? (
              <XIcon className="z-50 block h-20 w-20" aria-hidden="true" />
            ) : (
              <MenuIcon className="block h-20 w-20" aria-hidden="true" />
            )}
          </Disclosure.Button>
        </div>
      </div>
      <div className="-ml-2 overflow-y-scroll lg:hidden">
        {/*  모바일 panel */}
        <Disclosure.Panel>
          <div className="text-h4 px-[20px] pb-[16px] font-medium text-gray-700">
            <>
              <div className="pt-[16px] pb-[24px]">
                {currentUser ? (
                  <a onClick={handleLogout}>{t(s_menu.mypage.sign_out)}</a>
                ) : (
                  <LinkLocale href={urls.tutor.auth.signIn}>Log In</LinkLocale>
                )}
              </div>
              {currentUser ? (
                <TutorPortalEnterButton currentUser={currentUser} />
              ) : (
                <LinkLocale mode={SIGN_UP_MODE.TRIAL} href={urls.tutor.auth.signUp}>
                  <div className="w-full rounded bg-purple-500 py-[16px] text-center text-white">Become a Tutor</div>
                </LinkLocale>
              )}
            </>
          </div>
        </Disclosure.Panel>
      </div>
    </>
  )
}

const TutorMenu = ({ currentUser }: { currentUser: any }) => {
  const handleLogout = useLogoutTutor()

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className={classNames({ 'h-screen': open }, 'max-w-1040 mx-auto lg:px-20')}>
            <div className="hidden h-[72px] justify-between lg:flex">
              <div className="flex">
                {/* ----LOGO---- */}
                <div className="mr-32 flex flex-shrink-0 items-center">
                  <LinkLocale className="" href={urls.tutor.landing.home}>
                    <img src={RINGLE_LOGO_ROUND} className="mr-1 hidden h-[32px] w-auto lg:block" />
                  </LinkLocale>
                </div>
              </div>

              {/*--- RIGHT MENU ---*/}
              {/* (이벤트, FAQ, ...로그인, 무료체험) */}
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {currentUser ? (
                    <li className="inline-block list-none">
                      <a className="" onClick={handleLogout} data-ref={DATA_REF_KEY.btn_signout}>
                        <Button sm outlined clx="mr-16">
                          Sign Out
                        </Button>
                      </a>
                    </li>
                  ) : (
                    <li className="inline-block list-none">
                      <LinkLocale dataRef={DATA_REF_KEY.btn_signin} href={urls.tutor.auth.signIn}>
                        <Button
                          sm
                          outlined
                          clx="mr-16"
                          onClick={() => sendGTMBasicClickEvent(TUTOR_LANDING_BTN_ID.LOG_IN)}
                        >
                          Log In
                        </Button>
                      </LinkLocale>
                    </li>
                  )}
                  {/* 로그인 --> 로그아웃 */}

                  {currentUser ? (
                    <TutorPortalEnterButton currentUser={currentUser} />
                  ) : (
                    <LinkLocale href={urls.tutor.auth.signUp}>
                      <Button
                        primary
                        sm
                        lift
                        onClick={() => sendGTMBasicClickEvent(TUTOR_LANDING_BTN_ID.BECOME_A_TUTOR_TOP)}
                      >
                        Become a Tutor
                      </Button>
                    </LinkLocale>
                  )}
                </div>
              </div>
            </div>
            {/*--- MOBILE MENU ---*/}
            <div className="lgd:flex w-full flex-col lg:hidden">
              <MobileMenu currentUser={currentUser} open={open} />
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}

export default TutorMenu
