// @ts-strict-ignore
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import LinkLocale from '../../modules/i18n/LinkLocale'
import { s_common } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import urls from '../../modules/urls'
import CenterContainer from '../common/CenterContainer'

type CookieBannerPropsType = {
  handleClose: (e: React.SyntheticEvent) => void
}

const CookieBanner: React.FC<CookieBannerPropsType> = ({ handleClose }: CookieBannerPropsType) => {
  const [isMobile, setIsMobile] = useState(false)
  const [mounted, setMounted] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setMounted(true)
    setIsMobile(window.innerWidth < 576)
  }, [])

  const [ScrollY, setScrollY] = useState(0)
  function handleScroll(e) {
    if (ScrollY > 500) {
      setScrollY(window.pageYOffset)
      handleClose(e)
    } else {
      setScrollY(window.pageYOffset)
    }
  }
  useEffect(() => {
    function scrollListener() {
      window.addEventListener('scroll', handleScroll)
    }
    scrollListener()
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
  return (
    mounted && (
      <div className="fixed bottom-0 left-0 right-0 z-[1000] bg-white shadow-[0_2px_16px_0_rgba(67,86,100,0.15)]">
        <CenterContainer className="mx-auto" px16={isMobile}>
          <div
            className={classNames(
              { 'py-[20px]': !isMobile },
              { 'pt-[14px]': isMobile },
              { 'pb-[8px]': isMobile },
              'flex flex-col items-center justify-between sm:flex-row'
            )}
          >
            <div
              className={classNames(
                { 'text-h6': isMobile },
                classNames({ 'text-left': isMobile, 'text-14': !isMobile }),
                'text-gray-700'
              )}
            >
              {`${t(s_common.cookiebanner.first)} `}
              <LinkLocale target="_blank" href={urls.tutor.policy.privacy} title="use of cookies">
                <span className="underline">{t(s_common.cookiebanner.second)}</span>
              </LinkLocale>{' '}
              {t(s_common.cookiebanner.third)}
            </div>
            <div
              onClick={handleClose}
              className={classNames(
                { 'text-h6': isMobile },
                classNames({
                  'text-14 ml-8': !isMobile,
                }),
                'cursor-pointer self-start text-purple-500'
              )}
            >
              Close
            </div>
          </div>
        </CenterContainer>
      </div>
    )
  )
}

export default CookieBanner
