import classNames from 'classnames'
import Div from '../../components/basic/Div'
import { DivPropType } from '../basic/DivPropsType'

type ClassNamesValue = string | string[] | { [key: string]: boolean }

interface CenterContainerProps extends DivPropType {
  children?: React.ReactNode
  noPadding?: boolean
  className?: string
  clx?: ClassNamesValue
}

const CenterContainer = ({ children, noPadding, className, clx, ...others }: CenterContainerProps) => {
  return (
    <Div
      overflowHidden
      clx={classNames(
        'mx-auto max-w-[1080px]',
        {
          noPadding: 'px-0',
          'px-20': !noPadding,
        },
        className,
        clx
      )}
      {...others}
    >
      {children}
    </Div>
  )
}
export default CenterContainer
