export enum TUTOR_LANDING_BTN_ID {
  BECOME_A_TUTOR_TOP = '[BT]landing/become_a_tutor_top',
  LOG_IN = '[BT]landing/log_in',
  GET_STARTED_1 = '[BT]landing/get_started_1',
  GET_STARTED_2 = '[BT]landing/get_started_2',
  GET_STARTED_3 = '[BT]landing/get_started_3',
  FOOTER_ABOUT_US = '[BT]landing/ft_aboutus',
  FOOTER_FAQ = '[BT]landing/ft_faq',
  FOOTER_STUDENT = '[BT]landing/ft_student',
  FOOTER_FACEBOOK = '[BT]landing/ft_facebook',
  FOOTER_INSTAGRAM = '[BT]landing/ft_instagram',
  FOOTER_BLOG = '[BT]landing/ft_blog',
  FOOTER_POLICY = '[BT]landing/ft_policy',
}

export enum TUTOR_AUTH_BTN_ID {
  //* auth/enter-email
  SIGN_UP_VERIFY_EMAIL = '[BT]signup/verify_email',
  SIGN_UP_NO_STUDENT = '[BT]signup/no_student',
  SIGN_UP_LOG_IN = '[BT]signup/log_in',

  //* auth/verify-email
  VERIFY_EMAIL_CHANGE = '[BT]verify/email_change',
  VERIFY_RESEND_EMAIL = '[BT]verify/resend_email',
  VERIFY_CONTINUE = '[BT]verify/continue',

  //* auth/sign-in
  SIGN_IN = '[BT]auth/sign_in',

  //* auth/sign-in-error
  FORGOT_PW = '[BT]auth/forgot_pw',

  //* auth/password-reset
  PASSWORD_RESET = '[BT]auth/pass_reset',

  //* auth/basic-info
  BASIC_INFO_APPLY_REFERRAL_CODE = '[BT]basic-info/apply_referral_code',
  BASIC_INFO_SIGN_UP = '[BT]basic-info/sign_up',
}

export enum TUTOR_APPLICATION_BTN_ID {
  //* header in application
  HEADER_FAQ = '[BT]application/hd_faq',
  HEADER_BLOG = '[BT]application/hd_blog',
  HEADER_LOGOUT = '[BT]application/hd_logout',

  //* application/welcome
  WELCOME_BEGIN_PROCESS = '[BT]welcome/begin_process',
  WELCOME_POE_INFO = '[BT]welcome/poe_info', // POE = Proof of Education

  //* application/resume
  RESUME_GRADUATED = '[BT]resume/graduated',
  RESUME_UPLOAD_RESUME = '[BT]resume/resume_upload',
  RESUME_UPLOAD_POE = '[BT]resume/poe_upload',
  RESUME_NEXT = '[BT]resume/next',
}

/**
 * * TUTOR INPUT IDS
 */

export type TUTOR_INPUT_ID = TUTOR_AUTH_INPUT_ID | TUTOR_APPLICATION_INPUT_ID

export enum TUTOR_AUTH_INPUT_ID {
  //* auth/verify-email
  VERFICATION_CODE = '[IN]verify/verfication_code',

  //* auth/basic-info
  FIRST_NAME = '[IN]basic-info/first_name',
  LAST_NAME = '[IN]basic-info/last_name',
  PASSWORD = '[IN]basic-info/password',
  CONFIRM_PASSWORD = '[IN]basic-info/confirm_password',
  BIRTHDAY = '[IN]basic-info/birthday',
  LOCATION_COUNTRY = '[IN]basic-info/location_country',
  LOCATION_CITY = '[IN]basic-info/location_city',
  MOBILE_NUMBER = '[IN]basic-info/mobile',
  REFERRAL_CODE = '[IN]basic-info/referral',
}

export enum TUTOR_APPLICATION_INPUT_ID {
  //* application/welcome
  RESUME_HIGHER_EDUCATION = '[IN]resume/higher_education',
  RESUME_STUDY = '[IN]resume/study',
  RESUME_MAJOR = '[IN]resume/major',
  RESUME_GRAD_YEAR = '[IN]resume/grad_year',
}
