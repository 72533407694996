// @ts-strict-ignore
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CurrentUser } from '../../model/Common/common.interface'
import { IS_DEVELOPMENT } from '../../modules/envVars'
import { BRAZE_TUTOR_WEB_KEY_PROD } from '../../modules/vars'
import { brazeActions } from '../../reducer/brazeReducer'

type CsrOnlyTutorBrazePixelType = {
  currentUser: CurrentUser
  callback?: any
}
const CsrOnlyTutorBrazePixel = ({ currentUser, callback }: CsrOnlyTutorBrazePixelType) => {
  const dispatch = useDispatch()

  const [currentAppboy, setCurrentAppboy] = useState(null)

  const BRAZE_URL = IS_DEVELOPMENT ? 'sdk.iad-03.braze.com' : 'sdk.iad-06.braze.com'
  const BRAZE_WEB_KEY = IS_DEVELOPMENT ? null : BRAZE_TUTOR_WEB_KEY_PROD

  useEffect(() => {
    dispatch(brazeActions.setAppboy(currentAppboy))
  }, [currentAppboy])

  // https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#server-side-rendering-frameworks
  useEffect(() => {
    import('@braze/web-sdk')
      .then((ab) => {
        const appboy = ab
        if (appboy) {
          const initialized = appboy.initialize(BRAZE_WEB_KEY, {
            baseUrl: BRAZE_URL,
            enableLogging: IS_DEVELOPMENT,
            sessionTimeoutInSeconds: 1800,
            minimumIntervalBetweenTriggerActionsInSeconds: 5,
            manageServiceWorkerExternally: true,
            allowUserSuppliedJavascript: true,
            openInAppMessagesInNewTab: true,
          })
          const tutorForBraze = currentUser?.braze

          if (!initialized) {
            return
          }
          // console.log(appboy.isPushSupported())
          if (appboy.isPushSupported()) {
            appboy.requestPushPermission()
          }
          appboy.automaticallyShowInAppMessages()

          if (currentUser) {
            appboy.changeUser(`${tutorForBraze.id}`)
            const brazeUser = appboy.getUser()
            brazeUser.setEmail(`${tutorForBraze.email}`)
            brazeUser.setFirstName(`${tutorForBraze.first_name}`)
            try {
              if (tutorForBraze.birthday) {
                const birthdayArr = tutorForBraze.birthday.split('/')
                brazeUser.setDateOfBirth(birthdayArr[2], birthdayArr[0], birthdayArr[1])
              }
            } catch (e) {
              console.log('error while setting birthday in CsrOnlyTutorBrazePixel')
            }
            brazeUser.setCustomUserAttribute('is_ringle_member', tutorForBraze.is_ringle_member)
            brazeUser.setCustomUserAttribute('school', tutorForBraze.school)
            brazeUser.setCustomUserAttribute('school_tier', tutorForBraze.school_tier)
            brazeUser.setCustomUserAttribute('sign_up_date', tutorForBraze.sign_up_date)
            brazeUser.setCustomUserAttribute('RI', tutorForBraze.RI)
            brazeUser.setCustomUserAttribute('latest_lesson_start_time', tutorForBraze.latest_lesson_start_time)
            brazeUser.setCustomUserAttribute('past_lesson_count', tutorForBraze.past_lesson_count)
            brazeUser.setCustomUserAttribute('latest_slot_action_time', tutorForBraze.latest_slot_action_time)
            brazeUser.setCustomUserAttribute('future_open_slot_start_time', tutorForBraze.future_open_slot_start_time)
            brazeUser.setCustomUserAttribute('my_tutor_count', tutorForBraze.my_tutor_count)
            appboy.openSession() // new session or continue session
            setCurrentAppboy(appboy)
            if (callback && typeof callback === 'function') {
              callback(appboy)
            }
          }
        }
      })
      .then()
  }, [])

  return <></>
}

export default CsrOnlyTutorBrazePixel
