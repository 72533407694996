import Div from '../../components/basic/Div'
import { DivPropType } from '../basic/DivPropsType'

interface EmptyBlockPropsType extends DivPropType {
  h?: number
}

const EmptyBlock = ({ h = 150, ...props }: EmptyBlockPropsType) => {
  return <Div {...props} clx="block w-full" style={{ height: `${h}px` }}></Div>
}

export default EmptyBlock
