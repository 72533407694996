import LinkLocale from '../../../modules/i18n/LinkLocale'
import urls from '../../../modules/urls'
import { TUTOR_STATUS } from '../../../modules/vars'
import Button from '../../_legacy/Button'

interface TutorPortalEnterButtonType {
  currentUser: any
}
const TutorPortalEnterButton = ({ currentUser }: TutorPortalEnterButtonType) => {
  const getRedirectUrl = (currentUser) => {
    if (currentUser.not_allowed_log_in) {
      switch (currentUser.tutor_status_id) {
        case TUTOR_STATUS.DORMANT:
          return urls.tutor.landing.dormant
        case TUTOR_STATUS.OUT_THREE_STRIKE:
          return urls.tutor.landing.strike
        case TUTOR_STATUS.OUT_TEN_STRIKE:
          return urls.tutor.landing.strike
        case TUTOR_STATUS.HOLD:
          return urls.tutor.landing.holding
        case TUTOR_STATUS.BLACKLIST:
          return urls.tutor.landing.holding
        default:
          return urls.tutor.portal.home
      }
    } else {
      return urls.tutor.portal.home
    }
  }

  if (currentUser.teens_only && currentUser.tutor_status_id == TUTOR_STATUS.APPLICATION) {
    return (
      <LinkLocale href={urls.tutor.portal.home}>
        <Button primary sm lift>
          Enter
        </Button>
      </LinkLocale>
    )
  }

  return currentUser.status_sub_url ? (
    <LinkLocale href={currentUser.status_sub_url}>
      <Button primary sm lift>
        Enter
      </Button>
    </LinkLocale>
  ) : (
    <LinkLocale href={getRedirectUrl(currentUser)}>
      <Button primary sm lift>
        Enter
      </Button>
    </LinkLocale>
  )
}

export default TutorPortalEnterButton
