import classNames from 'classnames'
import { Fragment, useRef, useState } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import EmptyBlock from '../../components/common/EmptyBlock'
import TailwindWrapper from '../../components/container/TailwindWrapper'
import TutorMenu from '../../components/menu/TutorMenu'
import { useCurrentUser } from '../../modules/context/UserContext'

interface NewMainMenuProps {
  tutor: boolean
}

const NewMainMenu = ({ tutor }: NewMainMenuProps) => {
  const [height, setHeight] = useState(70)
  const topRef = useRef(null)
  const { currentUser } = useCurrentUser()

  return (
    <TailwindWrapper>
      <ReactResizeDetector handleHeight onResize={(w, h) => setHeight(h)}>
        <Fragment>
          <div
            ref={topRef}
            className={classNames(
              ['shadow-light-lg shadow-sm', 'bg-white'],
              'fixed-top fixed left-0 right-0 z-50 z-[111]'
            )}
          >
            {tutor && <TutorMenu currentUser={currentUser} />}
          </div>
        </Fragment>
      </ReactResizeDetector>
      <EmptyBlock h={height} />
    </TailwindWrapper>
  )
}

export default NewMainMenu
