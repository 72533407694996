// @ts-strict-ignore
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer/rootReducer'

const TailwindWrapper: React.FC<any> = ({ children }) => {
  const isOpen = useSelector((state: RootState) => state.searchBar.isOpen)

  return <div className={classNames(isOpen ? '-mb-167' : undefined, 'newTheme textBlack')}>{children}</div>
}

export default TailwindWrapper
