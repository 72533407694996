// @ts-strict-ignore
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import BodyClassName from 'react-body-classname'
import { useCookies } from 'react-cookie'
import { Slide, ToastContainer, toast as toastType } from 'react-toastify'
import BasicHead from '../../components/common/BasicHead'
import NewMainMenu from '../../components/common/NewMainMenu'
import { useCurrentUser } from '../../modules/context/UserContext'
import useIsAdminLogin from '../../modules/hooks/common/useIsAdminLogin'
import useIsWebview from '../../modules/hooks/common/useIsWebview'
import useTranslation from '../../modules/i18n/useTranslation'
import { ASIA_SEOUL, COOKIE_OPT } from '../../modules/vars'
import CookieBanner from '../banner/CookieBanner'
import CsrOnlyTutorBrazePixel from '../common/CsrOnlyTutorBrazePixel'
import AdminUserBadge from '../common/badge/AdminUserBadge'
import TailwindWrapper from './TailwindWrapper'

type NewBasicContainerPropsType = {
  noMainMenu?: any
  talk?: any
  tutor?: boolean
  ogData?: any
  toast?: any
  children?: any
}

const NewBasicContainer = (props: NewBasicContainerPropsType) => {
  const { noMainMenu, talk = false, tutor, ogData, toast, children } = props

  const { currentUser } = useCurrentUser()

  const [cookies, setCookies] = useCookies(['is_close_cookie_banner'])
  const isWebview = useIsWebview()
  const router = useRouter()
  const isAdminLogin = useIsAdminLogin()

  const handleCloseCookieBanner = (e) => {
    e.preventDefault()
    setCookies('is_close_cookie_banner', true, COOKIE_OPT)
  }
  const { isLocaleKO } = useTranslation()

  useEffect(() => {
    /**
     * <쿠키 배너 노출 기준>
     * 로그인 유저 : timezone이 한국이 아닐 경우 노출
     * 미로그인 유저: 언어가 영어이면 노출
     *
     * 위 케이스 외에는 쿠키 배너 노출하지 않도록  is_close_cookie_banner 쿠키에 저장
     * + close 버튼을 눌러서 쿠키배너를 닫은 경우에도 is_close_cookie_banner 쿠키에 저장
     */
    if ((currentUser && currentUser?.timezone === ASIA_SEOUL) || isLocaleKO) {
      setCookies('is_close_cookie_banner', true, COOKIE_OPT)
    }
  }, [currentUser?.timezone])

  useEffect(() => {
    if (!talk) {
      window?.ChannelIO('hideChannelButton')

      return () => window?.ChannelIO('showChannelButton')
    }
  }, [talk])

  return (
    <>
      <BodyClassName className={`newTheme text-black ${null}`} />
      <BasicHead ogData={ogData} />
      {toast && (
        <ToastContainer
          style={{ width: '370px', height: '36px', marginBottom: '64px' }}
          toastClassName=" min-h-0 bg-black text-white px-8 text-center h4 text-sBody rounded flex bg-opacity-80"
          position={toastType.POSITION.BOTTOM_CENTER}
          closeButton={false}
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          transition={Slide}
          pauseOnHover
          enableMultiContainer
        />
      )}
      <CsrOnlyTutorBrazePixel currentUser={currentUser} />
      {!noMainMenu && <NewMainMenu tutor={tutor} />}
      {children}
      <TailwindWrapper>{isAdminLogin && <AdminUserBadge />}</TailwindWrapper>
      {!isWebview && cookies.is_close_cookie_banner !== 'true' && (
        <CookieBanner handleClose={handleCloseCookieBanner} />
      )}
    </>
  )
}

export default NewBasicContainer
