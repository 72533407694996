// @ts-strict-ignore
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { IOpenGraphData, ISeoData } from '../../model/Common/common.interface'
import { RINGLE_DOMAIN } from '../../modules/envVars'
import { LOCALE_EN, LOCALE_JA, LOCALE_KO, LOCALE_VI, LOCALE_ZH_CHS, LOCALE_ZH_CHT } from '../../modules/i18n/config'
import { s_common, s_head } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { CDN_URL, FACEBOOK_APP_ID } from '../../modules/vars'

// meta 태그는 Head 엘리먼트의 바로 자식 노드이거나. 1 레벨 깊이의 <Fragment>로 감싸져 잇어야 한다.
// 따라서 컴포넌트로 넣기보다 함수를 호출함

interface BasicHeadProps {
  seoData?: ISeoData
  ogData?: IOpenGraphData
}

const BasicHead = ({ seoData, ogData }: BasicHeadProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [lang, setLang] = useState(router.query?.lang ?? LOCALE_EN)
  const [originPath, setOriginPath] = useState(router.pathname.replace('/[lang]/', '/'))

  const [fontFamily, setFontFamily] = useState('Noto+Sans+KR:wght@100;300;400;500;700;900')
  useEffect(() => {
    switch (lang) {
      case LOCALE_KO:
      case LOCALE_EN:
        setFontFamily('Noto+Sans+KR:wght@100;300;400;500;700;900')
        break
      case LOCALE_JA:
        setFontFamily('Noto+Sans+JP:wght@100;300;400;500;700;900')
        break
      case LOCALE_ZH_CHS:
        setFontFamily('Noto+Sans+SC:wght@100;300;400;500;700;900')
        break
      case LOCALE_ZH_CHT:
        setFontFamily('Noto+Sans+TC:wght@100;300;400;500;700;900')
        break
      case LOCALE_VI:
        setFontFamily('Noto+Sans:wght@400;700')
        break
    }
  }, [lang])

  useEffect(() => {
    setOriginPath(router.pathname.replace('/[lang]/', '/'))

    if (router.query.lang && !Array.isArray(router.query.lang)) {
      setLang(router.query.lang)
    }
  }, [router.pathname, router.query])

  const getValue = useCallback(
    (value: string, ogValue: string, fallbackValue: string) => (value ? value : ogValue ? ogValue : fallbackValue),
    []
  )

  let doc = null

  doc = {
    name: t(s_common.ringle),
    title: getValue(seoData?.pageTitle, ogData?.title, t(s_head.tutor.title)),
    desc: getValue(seoData?.pageDescription, ogData?.desc, t(s_head.tutor.desc)),
    keywords: getValue(seoData?.pageKeywords, ogData?.keywords, t(s_head.tutor.keywords)),
    author: ogData?.author ? ogData.author : t(s_head.tutor.author),
    og: {
      url: ogData?.ogUrl ? ogData.ogUrl : 'https://ringletutor.com/' + lang + originPath,
      type: 'website',
      siteName: ogData?.siteName ? ogData.siteName : t(s_common.ringle),
      title: ogData?.title ? ogData.title : t(s_head.tutor.title),
      description: ogData?.desc ? ogData.desc : t(s_head.tutor.desc),
      image: ogData?.image ? ogData.image : `${CDN_URL}/tutor_video_thumbnail/Referral_og_1200x630.png`,
      imageSecureUrl: ogData?.image ? ogData.image : `${CDN_URL}/tutor_video_thumbnail/Referral_og_1200x630.png`,
    },
    noscript: t(s_head.tutor.noscript),
  }

  const vars = {
    googleVerification: 'qqGVDMJYwbSlrfP8WpCyTJU4BgbsGS21M06nTejKjlA',
    naverVerification: 'eecb2aaf488f45f7202f76e6a3c5fd8ec36009e3',
  }

  return (
    <Head>
      <title>{doc.title}</title>
      <meta charSet="utf-8" />
      <meta name="naver-site-verification" content={vars.naverVerification} />
      <meta name="google-site-verification" content={vars.googleVerification} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0"
      />

      <meta name="name" content={doc.name} />
      <meta name="description" content={doc.desc} />
      <meta name="keywords" content={doc.keywords} />
      <meta property="og:url" content={`${doc.og.url}/`} />
      <meta property="og:type" content={doc.og.type} />
      <meta property="og:site_name" content={doc.og.siteName} />
      <meta property="og:title" content={doc.og.title} />
      <meta property="og:description" content={doc.og.description} />
      <meta property="og:image" content={doc.og.image} />
      <meta property="og:image:secure_url" content={doc.og.imageSecureUrl} />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />

      {seoData?.pageCanonicalPath ? (
        <link rel="canonical" href={seoData?.pageCanonicalPath} />
      ) : (
        <link rel="canonical" href={'https://' + RINGLE_DOMAIN + '/' + lang + originPath} />
      )}

      <link rel="alternate" href={'https://' + RINGLE_DOMAIN + '/en' + originPath} hrefLang="en" />

      <link href={`https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`} rel="stylesheet" id="new" />

      {/** noscript **/}
      <noscript>{doc.noscript}</noscript>
    </Head>
  )
}

export default React.memo(BasicHead)
